import React from "react";

var AboutDesc = props => {
  return (
    <div>
      <p>{props.info.desc}</p>
      <br />
    </div>
  );
};

export default AboutDesc;
